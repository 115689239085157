<template>
	<div class="track">
		<img class="bg-img" src="@/assets/img/trackbackground.png" />
		<div class="trackInfo" v-if="trackId" v-cloak>
			<div class="head">
				<div class="trackTitle">包裹追踪查询</div>
				<div class="orderid">订单号 {{orderid}}</div>
			</div>
			<!-- <div class="detailInfo">国内派送公司:中国邮政  国内派送单号：{{orderid}}(跨境清关完成后，您也可以在<a href="https://www.ems.com.cn/" class="a-href">{{company}}</a>官网查询)</div> -->
			<div class="detail-box">
        <div class="line" v-for="(item,index) in trackArr" :key="index" :class="{'nowLine':index == 0, 'departure':index == trackArr.length - 1}">
          <span class="time">{{item.createTime}}</span>
          <span class="step">
            <span class="empty"></span>
          </span>
        <span class="content">{{item.content}}</span>
        </div>
      </div>
		</div>

    <!-- 搜索文本框 -->
    <div class="search-box" v-else v-cloak>
      <el-input
        class="search-textarea"
        type="textarea"
        onkeyup="this.value = this.value.replace(/[\W]/g,'');"
        resize="none"
        placeholder="One per line"
        v-model="searchOrder">
      </el-input>
      <el-button type="primary" class="search-btn" @click="searchTrack">Track</el-button>
    </div>
	</div>
</template>
<script>
import { showAllRecordByOrderPackageId } from '@/api/user/user'
import { getOrderIdByNumber } from '@/api/user/user'
export default {
	name:'track',
	data(){
		return{
			//订单号
			orderid: "",
			//跟踪订单id
			trackId:0,
			//国内派送单号
			sendid:"",
			//快递公司
			company:"",

			trackArr:[],
			// 搜索的订单id
      searchOrder:'',
		}
	},
	created(){
		this.trackId = this.$route.query.id;
    this.showAllRecordByOrderPackageId();
    // console.log("进入包裹跟踪页面");
  },
	methods:{
    // 查询
    searchTrack(){
      this.getOrderIdByNumber()
    },
    // 根据订单号查询id
    getOrderIdByNumber(){
      let update = {
        number: this.searchOrder
      }
      getOrderIdByNumber(update).then(res =>{
        if(res.data.code === 0){
          this.trackId = res.data.data.orderId;
          this.showAllRecordByOrderPackageId()
        }else{
          this.$message.error(res.data.message);
        }
       
      })
    },
    // 查询物流信息
		showAllRecordByOrderPackageId(){
      let update = {
        id: this.trackId
      }
      showAllRecordByOrderPackageId(update).then(res =>{
				if(res.data.code === 0){
          this.trackArr = res.data.data.list;
					this.orderid = res.data.data.mailOrderNo;
					this.company = res.data.data.company;
        }
        console.log(res, "包裹追踪接口返回值");
      })
    },
	}
}
</script>
<style lang="less" scoped>
.detail-box{
  height:480px;
  overflow:scroll;
}
.track{
			// padding-top: 270px;
			// padding-left: 460px;
			background: #ccc;
			position: relative;
			height: 100%;
			.bg-img{
				width: 100%;
        min-height: 100%;
				// height: 1080px;
			}
			.a-href{
				margin:0;
				padding:0;
				color:#5ABE64;
			}
      .search-box{
        left:50%;
        top:50%;
        width: 24%;
        position:absolute;
        transform: translateX(-50%);
      }
      .search-textarea{
        height:200px;
        border-radius: 8px;
        &::v-deep .el-textarea__inner{
          height:200px;
          &:hover {
            border:1px solid #6D5EFE;
          }
          &:focus{
            border:1px solid #6D5EFE;
          }
        }
      }
      .search-btn{
        width: 100%;
        margin-top: 20px;
        background: #6D5EFE;
        border:1px solid #6D5EFE;
      }
	.trackInfo{
		// padding-top: 30px;
		height: 700px;
		width: 1000px;
		border-radius: 8px;
		opacity: 0.9;
		background: #fff;
		position:absolute;
		left:50%;
		top:27%;
		transform: translateX(-50%);

		.head{
			margin-bottom: 10px;
			// padding-bottom: 10px;
			padding:30px 0;
			border-bottom: 1px solid #D8D8D8;
		}
		.trackTitle{
			margin: 0 auto;
			width: 120px;
			height: 28px;
			font-size: 20px;
			font-weight: 600;
			color: #1E2B4F;
			line-height: 28px;
		}
		.detailInfo{
			margin-top: 30px;
			margin-bottom: 24px;
			text-align: center;
			font-size: 14px;
			height: 20px;
		}
		.orderid{
			margin: 0 auto;
			margin-top: 10px;
			width: 200px;
			height: 20px;
			font-size: 14px;
			font-weight: 400;
			color: #1E2B4F;
			line-height: 20px;
			text-align: center;
		}
		.time{
			margin-left: 65px;
		}
		.line{
		display: flex;
		align-items: center;
		height: 20px;
		color: #1E2B4F;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 30px;
	}
	.step{
		margin: 0 30px;
		display: inline-block;
		width: 10px;
		height: 10px;
		background:#D8D8D8;
		border-radius: 50%;
		position: relative;
		.empty{
			position: absolute;
			display: inline-block;
			width: 0px;
			height: 40px;
			border-right: 1px dashed #C2BBC8;
			bottom: -42px;
			left:50%;
			transform: translateX(-50%);
		}
	}
	// 当前最新的位置
	.nowLine{
		color: #6D5EFE;
		.step{
			background: #6D5EFE;
			
			&::before{
				content: '';
				width: 13px;
				height: 13px;
				display: inline-block;
				border-radius: 50%;
				border:1px solid #C0BAFF;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
	// 最初的位置
	.departure{
		.step{
			background: #5ABE64;
			.empty{
				display: none;
			}
		}
	}
	}
	
}
</style>